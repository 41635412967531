import { TdsBodyCell, TdsHeaderCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader } from '@scania/tegel-react';
import { useSettings } from '../../context/settingsContext';
import { useAreaContext } from '../../context/areaContext';

const SensorData = () =>{


    const { sensorData } = useAreaContext();
    const { currentSettings } = useSettings();

    const convert = (arr:any,precision:number)=>{
        let newArr:any[] = [];
        for(let i=0; i<arr.length; i++){
            newArr.push(arr[i].toFixed(precision));
        }
        return newArr;
    }

    return (
        <TdsTable vertical-dividers="true" compact-design="true">
        <TdsTableHeader>
            <TdsHeaderCell cell-key="track" cell-value="Track id"></TdsHeaderCell>
            <TdsHeaderCell cell-key="class" cell-value="Class"></TdsHeaderCell>
            <TdsHeaderCell cell-key="topic" cell-value="Topic"></TdsHeaderCell>
            <TdsHeaderCell cell-key="position" cell-value="Position"></TdsHeaderCell>
            <TdsHeaderCell cell-key="velocity" cell-value="Velocity"></TdsHeaderCell>
        </TdsTableHeader>
        <TdsTableBody>

           {sensorData && Object.keys(sensorData).length>0 ? (
                //let keys = Object.keys(sensorData[0]);
                Object.values(sensorData).map((msg:any, idx) => (
                    Object.entries(msg).map((trackid,data)=>(
                    <TdsTableBodyRow key={trackid[0]}>
                    <TdsBodyCell
                        cell-value={trackid[0]}
                        cell-key="track"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["class_id"]}
                        cell-key="class"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["topic"]}
                        cell-key="topic"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={convert(trackid[1]!["lalo"],currentSettings.sensor.decimalPlacesPosition.value).toString()}
                        cell-key="position"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={convert(trackid[1]!["vel"],currentSettings.sensor.decimalPlacesVelocity.value).toString()}
                        cell-key="velocity"
                        disable-padding="false"
                    ></TdsBodyCell>
                    </TdsTableBodyRow>

                ))))) : (
                <TdsTableBodyRow></TdsTableBodyRow>
                )}
        </TdsTableBody>
        </TdsTable>
    )
}
export default SensorData;
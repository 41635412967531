import { createContext, useContext, useEffect, useState } from "react"
import { useWebSocketContext } from "./websocketContext";
import proj4 from "proj4";
import { useSettings } from "./settingsContext";

/*interface AreasObject {
    [key : string]: {
        name: string,
        preselected?: boolean,
        topic: string[]
    }
}
export type areaType = {
  name: string,
  preselected?: boolean,
  topic: string[],
}

const emptyAreasObject = ():AreasObject=>{
    return {
        test:{
            name:"",
            preselected: false,
            topic:[""],
            }
        }
    }*/

export type AreaContextT = {
    areas:string[],
    areaData:{},
    activeArea: string,
    sensorData: {},
    latencyData: {},
    vehicleData: {},
    predictionData:{},
    vehiclePredictionData:{},
    sensorSubscription: string,
    startSubscription: (t: string)=>void,
    stopSubscription: ()=>void,
    setActiveArea: (t: string) => void,
    emptyDataBuffers: ()=>void,
    legend:{}
  }

export const AreaContext = createContext<AreaContextT>(
    {
      areas: [],//emptyAreasObject(),
      areaData: {},
      activeArea: "",
      sensorData: {},
      latencyData: {},
      vehicleData: {},
      predictionData: {},
      vehiclePredictionData: {},
      sensorSubscription: "",
      startSubscription: () => {},
      stopSubscription: () => {},
      setActiveArea: ()=>{},
      emptyDataBuffers: ()=>{},
      legend: {},
    }
  );

export const AreaProvider = (props:any)=> {
     const areas = [
          "stc",
          "valhal",
          "itrl",
         ];
    const legend = {
      "0": ["unkown","gold",2],
      "1":["bicycle", "red",2],
      "4":[ "pedestrian", "fuchsia",2], 
      "18":[ "light vehicle", "orange",4 ],
      "34":[ "heavy vehicle", "green",4],
    }
    const [areaData, setAreaData] = useState<any>({});
    const [activeArea, setActiveArea] = useState<string>("");
    const {currentSettings} = useSettings();
    const { sendMessage,lastJsonMessage } = useWebSocketContext();
    const [sensorSubscription, setSensorSubscription] = useState<string>("");
    const [sensorData, setSensorData] = useState<any>({});
    const [latencyData, setLatencyData] = useState<any>({});
    const [vehicleData, setVehicleData] = useState<any>({});
    const [predictionData, setPredictionData] = useState<any>({});
    const [vehiclePredictionData, setVehiclePredictionData] = useState<any>({});

    const getShortName = (name:string) =>{
      for (let index = 0; index < areas.length; index++) {
        if (areaData[areas[index]]["name"]["value"]===name){
          return areas[index]
        }

      }
      return "unkown"
    }
    proj4.defs("SWEREF99","+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
    //EPSG 3006
    //WGS84 / 4326
    useEffect(() => {
      let ad = {}
      areas.map((areaName)=>{
        ad[areaName] = currentSettings[areaName]
      }
      )
      setAreaData(ad);
      setActiveArea(areas[0]);//ad["stc"].name.value);
    }, [currentSettings.stc,currentSettings.itrl,currentSettings.valhal]);

    useEffect(() => {
      if (activeArea !== "") {
        emptyDataBuffers();
        //startSubscription(areaData[getShortName(activeArea)]["topic"]["value"])
        if (activeArea === "stc" && currentSettings.stc.directconnect.value === true){
            startSubscription(areaData[activeArea]["topic"]["value"].replace("viscando.testtrack.","viscando.stc."))
        } else {
            startSubscription(areaData[activeArea]["topic"]["value"])
        }
      }
    }, [activeArea,currentSettings.stc.directconnect.value]);


    useEffect(()=>{
        if (lastJsonMessage !== null && Object.keys(lastJsonMessage!).length>0) {
            if (lastJsonMessage!.hasOwnProperty('topic')){
              let topic =lastJsonMessage!["topic"]
              delete lastJsonMessage!["topic"]
              //for the sensor not to overwrite other sensors, we create this nested structure with topic as key
              let tmp = {[topic]: lastJsonMessage}
              //we use prev state to not overwrite other sensors/vehicles publishing
              if (topic.includes("/tracks")) {
                  setSensorData((prev:object) => ({...prev,...tmp}));
              }
              else if (topic.includes("/latency")){
                  setLatencyData((prev:object)=>({...prev,...tmp}))
              }
              else if (topic.includes("/status/")){
                  setVehicleData((prev:object)=>({...prev,...tmp}))
              }
              else if (topic.includes("/predictions")){
                  // here we don't want the nested structure of tmp wich was because of the different sensors publisher
                  setPredictionData(lastJsonMessage[topic])
              }
              else if (topic.includes("/status_pred")){
                  // here we don't want the nested structure of tmp wich was because of the different sensors publisher
                  setVehiclePredictionData(lastJsonMessage[topic])
              }
              else {
                  console.log("Don't know what to do with message")
              }
            }
          }
    },[lastJsonMessage])

   const startSubscription=(topics:string)=>{
        if (typeof(topics) !== 'undefined' && topics != null && topics.length>0) {
            emptyDataBuffers()
            sendMessage(`{"action":"sensor-subscribe", "message":${JSON.stringify(topics)}}`)
            setSensorSubscription(topics)
        }
    }

  const emptyDataBuffers=()=>{
    setSensorData({})
    setLatencyData({})
    setVehicleData({})
    setPredictionData({})
  }

    const stopSubscription=()=>{
        console.log("Unsubscribing")
        sendMessage(`{"action":"sensor-subscribe", "message":"stop"}`)
        emptyDataBuffers()
        setSensorSubscription("")
    }

    const contextValue = {
        areas,
        areaData,
        activeArea,
        sensorData,
        latencyData,
        vehicleData,
        predictionData,
        sensorSubscription,
        vehiclePredictionData,
        startSubscription,
        stopSubscription,
        setActiveArea,
        emptyDataBuffers,
        legend
    };

    return (
        <AreaContext.Provider value={contextValue}>
            {props.children}
        </AreaContext.Provider>
    );
}

export const useAreaContext=()=> {
  return useContext(AreaContext);
}

import React,{ useState } from "react";
import Architecture from "../assets/architecture/f5gr_architecture.jpg";
import ImageZoom from "react-image-zooom";
const ArchitecturePage = () => {
    return (
        <>
            <h1 className="tds-headline-01 tds-u-mb-2">Architecture overview</h1>
            <p className="tds-body-01">
            <div>Click on figure to zoom</div>
            <ImageZoom className="FigureZoom" src={Architecture} alt="Architecture" zoom="200"/> 
            </p>
        </>
    );
};

export default ArchitecturePage;

import SettingsParameter from "./SettingsParameter";

const SettingsParagraph = ({path,settings}) =>{
    return(
      <>
        <div className="tds-row" key={path+"row"}>
          <div className="tds-col-max-12" key={path+"row"+"path"}><h2>{path}</h2></div>
        </div>
        {Object.keys(settings[path]).map((key2, index2) =>{
            return(
              <div key={key2+"d"} className="component-wrapper" style={{ width: "600px" }}>
                <SettingsParameter name={key2} path={path + "."+key2} />
              </div>
            )
        })}
      </>
      )
}

export default SettingsParagraph;

import AreaSelector from "../components/control/AreaSelector";
import SensorData from "../components/sensor/SensorData";
import SensorLatency from "../components/sensor/SensorLatency";
import VehicleData from "../components/sensor/VehicleData";

const SensorPage = () => {

    return (
    <>
        <h1 className="Tdsheadline-01 Tdsu-pb3">Sensor data</h1>
        <AreaSelector></AreaSelector>
        <SensorData></SensorData>
        <SensorLatency></SensorLatency>
        <h3>Vehicle data</h3>
        <VehicleData></VehicleData>
    </>
  );
};

export default SensorPage;
import { TdsLink } from "@scania/tegel-react";

const AboutPage = () => {
    return (
        <>
            <h1 className="tds-headline-01 tds-u-mb-2">General Info</h1>
            <p className="tds-body-01">
            <h2>Vehicle control</h2>
            To send your commands when pressing the buttons, your user needs to be part of a specific group, contact    
            <TdsLink>
                <a href="mailto:bas.oremus@scania.com"> Bas </a>
            </TdsLink>

            <h2>Vehicles</h2>
            When sending status messages according to spec, it should automatically pop up in the vehicle list.
            </p>
        </>
    );
};

export default AboutPage;

import React from "react";
import ReactDOM from "react-dom/client";
import { defineCustomElements } from "@scania/tegel-react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import App from "./App";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import { UserProvider } from "./context/userContext";
import { SettingsProvider } from "./context/settingsContext";
import { WebSocketProvider } from "./context/websocketContext";
import { ResizeProvider } from "./context/resizeContext";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import SensorPage from "./pages/SensorPage";
import ControlPage from "./pages/ControlPage";
import { AreaProvider } from "./context/areaContext";
import ArchitecturePage from "./pages/ArchitecturePage";
import GraphsPage from "./pages/GraphsPage";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: String(process.env.REACT_APP_COGNITO_USER_POOL_ID),
      userPoolClientId: String(process.env.REACT_APP_COGNITO_APP_CLIENT_ID),
    loginWith:{
      oauth: {
          domain: String(process.env.REACT_APP_COGNITO_HOSTED_UI),
          scopes: ["phone", "email", "profile", "openid"],
          redirectSignIn: [String(process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN)],
          redirectSignOut: [String(process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN)],
          responseType: "token",
        },
      }
    }
  }
  },
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            path: "",
            element: <HomePage />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
          {
            path: "about",
            element: <AboutPage />,
          },
          {
            path: "sensor",
            element: <SensorPage />,
          },
          {
            path: "architecture",
            element: <ArchitecturePage />,
          },
          {
            path: "control",
            element: <ControlPage />,
          },
          {
            path: "graphs",
            element: <GraphsPage />,
          },
        ],
      },
    ],
  },
]);

defineCustomElements();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
    <UserProvider>
      <SettingsProvider>
        <WebSocketProvider>
          <AreaProvider>
          {/*<PositionProvider>*/}
            <ResizeProvider>
              <RouterProvider router={router} />
            </ResizeProvider>
        {/*</PositionProvider>*/}
          </AreaProvider>
        </WebSocketProvider>
      </SettingsProvider>
    </UserProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

//reportWebVitals();

import { TdsBodyCell, TdsButton, TdsHeaderCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader } from "@scania/tegel-react";
import { useAreaContext } from "../../context/areaContext";
import { useWebSocketContext } from "../../context/websocketContext";
import { v4 as uuidv4 } from 'uuid';
import { useSettings } from "../../context/settingsContext";
import SettingsParameter from "../settings/SettingsParameter";
import { useUserContext } from "../../context/userContext";

const AreaVehicles = ()=>{
    const {isPartOfGroup} = useUserContext()
    const { vehicleData,activeArea,areaData,vehiclePredictionData } = useAreaContext();
    const {currentSettings,setSettings} = useSettings();
    const { sendMessage } = useWebSocketContext();
    const sendCmd = (veh:string,cmd:string) =>{
        if (isPartOfGroup("VehicleControl")){
            let coord;
            let topic = veh.replace("status","cmd");
            let activeVeh = vehicleData[veh][veh]
            veh = activeVeh.vehid;
            if (cmd==="stop") {
                //replace with current/next position
                //currently we don't have access to prediction positions here.
                let predPoint = vehiclePredictionData[activeVeh.vehid]
                coord = {
                    x:predPoint[Math.round(currentSettings.vehicleControl.stopCoordTimeAhead.value/0.2)][0],//activeVeh.position.x,
                    y:predPoint[Math.round(currentSettings.vehicleControl.stopCoordTimeAhead.value/0.2)][1],//activeVeh.position.y,
                }
            }else{
                //topic = `kth/${activeArea}/cmd/${veh}`
                coord = {
                    x:areaData[activeArea][cmd].value[0],
                    y:areaData[activeArea][cmd].value[1]
                }
            }
            let msg = {
                "topic": topic,
                "cmdid": uuidv4(),
                "vehicle": veh,
                "area": activeArea,
                "cmd": "goto",
                "coord": coord,
                "speed": areaData[activeArea]["speed"].value,
                "time": new Date().toISOString(),
            }
            let sendMsg = `{"action":"vehicle-cmd", "message":${JSON.stringify(msg)}}`
            sendMessage(sendMsg)
            console.log("Send " + sendMsg)
        }
        else{
            alert("You don't have the correct access rights for sending vehicle commands!")
        }
    }
    return (
        <>
        <TdsTable vertical-dividers="false" compact-design="false" responsive>
        <TdsTableHeader>
            <TdsHeaderCell cell-key="truck" cell-value="Vehicle"></TdsHeaderCell>
            <TdsHeaderCell cell-key="driver" cell-value="Status"></TdsHeaderCell>
            <TdsHeaderCell cell-key="stop" cell-value="Stop"></TdsHeaderCell>
            <TdsHeaderCell cell-key="country" cell-value="A"></TdsHeaderCell>
            <TdsHeaderCell cell-key="mileage" cell-value="B"></TdsHeaderCell>
            <TdsHeaderCell cell-key="mileage" cell-value="C"></TdsHeaderCell>
        </TdsTableHeader>
        <TdsTableBody>
{vehicleData && Object.keys(vehicleData).length>0 ? (
     //let keys = Object.keys(sensorData[0]);
     Object.values(vehicleData).map((msg:any, idx) => (
         Object.entries(msg).map((trackid,data)=>(
         <TdsTableBodyRow key={trackid[0]}>
         <TdsBodyCell
             cell-value={trackid[1]!["vehid"]}
             cell-key="vehicle"
             disable-padding="false"
         ></TdsBodyCell>
         <TdsBodyCell
             cell-value={trackid[1]!["status"]}
             cell-key="status"
             disable-padding="false"
         ></TdsBodyCell>
         <TdsBodyCell
             cell-value=''
             cell-key={`stop-${trackid[1]!['vehid']}`}
             disable-padding="false"
         ><TdsButton text={"Stop"} type="button" variant="danger" fullbleed onClick={() => {sendCmd(trackid[0],'stop')}}></TdsButton></TdsBodyCell>
         <TdsBodyCell
             cell-value=''
             cell-key={`A-${trackid[1]!['vehid']}`}
             disable-padding="false"
         ><TdsButton text={"Goto A"} type="button" variant="primary" fullbleed onClick={() => {sendCmd(trackid[0],'A')}}></TdsButton></TdsBodyCell>
         <TdsBodyCell
             cell-value=''
             cell-key={`B-${trackid[1]!['vehid']}`}
             disable-padding="false"
         ><TdsButton text={"Goto B"} type="button" variant="primary" fullbleed onClick={() => {sendCmd(trackid[0],'B')}}></TdsButton></TdsBodyCell>
                  <TdsBodyCell
             cell-value=''
             cell-key={`C-${trackid[1]!['vehid']}`}
             disable-padding="false"
         ><TdsButton text={"Goto C"} type="button" variant="primary" fullbleed onClick={() => {sendCmd(trackid[0],'C')}}></TdsButton></TdsBodyCell>
         </TdsTableBodyRow>

     ))))) : (
     <TdsTableBodyRow></TdsTableBodyRow>
     )}
        </TdsTableBody>
        </TdsTable>
        <SettingsParameter name={`${activeArea}.speed`} path={`${activeArea}.speed`}/>
        </>
    )
}
export default AreaVehicles;
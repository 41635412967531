import React, {  useEffect, useState,useRef} from "react";
import { Buffer } from "buffer";
import { useWebSocketContext } from "../context/websocketContext";
import {TdsDropdown, TdsDropdownOption, TdsBodyCell, TdsHeaderCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader} from "@scania/tegel-react";
export const Graphs = () => {
    const {sendMessage,lastJsonMessage} = useWebSocketContext();
    const [graphsMessage, setGraphsMessage] = useState(null);
    const graphsTempData = useRef([]);
    const [selectedGraph, setSelectedGraph] = useState(0);
    const [selectedTime, setSelectedTime] = useState('-PT3H');
    const timePeriod=["-PT3H","-PT6H","-PT12H","-P3D","-P7D"];
    const periods = {
        "-PT3H": 300,
        "-PT6H": 300,
        "-PT12H":600,
        "-P3D": 3600,
        "-P7D": 3600*8
    }
    const graphs =[
        {
            name: "Sensor latency",
            metrics: [
                [ "Test5GRide", "End-to-end Latency", "Device", "viscando-stc-0", { "color": "#d62728" } ],
                [ "...", "viscando-stc-1", { "color": "#ff9896" } ],
                [ "...", "viscando-testtrack-0", { "color": "#1f77b4" } ],
                [ "...", "viscando-testtrack-1", { "color": "#17becf" } ]
            ],
            view: "timeSeries",
            stacked: false,
            stat: "Average",
            period: 300,
            region: "eu-north-1",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            title: "Latency from sensor to cloud",
            start: "-PT3H",
            end: "P0D",
            yAxis: {
                left: {
                    min: 0
                }
            },
        },
        {
            name: "Active Subscribers",
            title: "Active subscribers",
            metrics: [
        
                [ "Future5GRideTest", "Active Subscriptions", "Device", "AS" ]
            ],
            view: "timeSeries",
            stacked: false,
            region: "eu-north-1",
            stat: "Average",
            period: 300,
            start: "-PT3H",
            end: "P0D", 
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
        }
    ]

    useEffect(()=>{
        if (lastJsonMessage !== null && lastJsonMessage.hasOwnProperty('graphs')) {
            console.log("Received graph data");
            if (lastJsonMessage.hasOwnProperty("current_chunk")){
              if (lastJsonMessage.current_chunk===1){
                //it is the first data, put in in temp storage
                graphsTempData.current =lastJsonMessage.graphs;
              }else{
                //add next chunkt to temp storage
                graphsTempData.current = graphsTempData.current.concat(lastJsonMessage.graphs);
              }
              if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && graphsTempData.current.length>0){
                //now all data has arrived, set it to the output value
                console.log("All graphs data received")
                setGraphsMessage(graphsTempData.current);
              }
            } else {
              setGraphsMessage(lastJsonMessage.graphs)
            }
        }
    },[lastJsonMessage])

    const getData = (def=null) =>{
        /* when using state the change does not come trough immediate*/
        console.log("Graphs: fetching data");
        if (def === null){
            def = graphs[selectedGraph]
        }
        def["start"]=selectedTime;
        def["period"]=periods[selectedTime];
        if (!def["title"].includes(periods[selectedTime]) ){
            def["title"]=def["title"] + " per " + periods[selectedTime] + "s";
        }

        console.log("Selected: ",selectedGraph)
        console.log(`Graph def=${JSON.stringify(def)}`)
        sendMessage(`{"action":"graph","graphdef":"${Buffer.from(JSON.stringify(def)).toString('base64')}"}`)
    }

    useEffect(()=>{
        getData();
        const timerId = setInterval(() => {
            getData();
          }, 5*60 * 1000);
          return () => clearInterval(timerId);
    },[selectedGraph,selectedTime])


    return (
        <>
        <TdsTable vertical-dividers="true" compact-design="true"></TdsTable>
        <TdsTableBody>
        <TdsTableBodyRow key="sens">
        <TdsBodyCell cell-value="" cell-key="track" disable-padding="false" >
        <TdsDropdown name="dropdown" label="Select type of graphs" default-value={selectedGraph} label-position="outside"
        size="lg" open-direction="auto" onTdsChange={e => setSelectedGraph(e.detail.value)} >       
        {graphs.map((o,idx) => (<TdsDropdownOption value={idx}>{o.name}</TdsDropdownOption>))}
        </TdsDropdown>
        </TdsBodyCell>
        <TdsBodyCell cell-value="" cell-key="time" disable-padding="false" >
        <TdsDropdown name="dropdown" label="Time interval" default-value={selectedTime} label-position="outside"
        size="lg" open-direction="auto" onTdsChange={e => setSelectedTime(e.detail.value)} >       
        {timePeriod.map((o,idx) => (<TdsDropdownOption value={o}>{o}</TdsDropdownOption>))}
        </TdsDropdown>
        </TdsBodyCell>
        </TdsTableBodyRow>
        </TdsTableBody>

        {graphsMessage ? <img src={`data:image/png;base64,${graphsMessage}`}/>:<div>No data</div>}

        </>
    );
};
export default Graphs;
import { useEffect, useState } from "react";
import AreaMasterControl from "../components/control/AreaMasterControl";
import AreaSelector from "../components/control/AreaSelector";
import AreaVehicles from "../components/control/AreaVehicles";
import AreaOverview from "../components/control/AreaOverview";
import { useAreaContext } from "../context/areaContext";
import { useSettings } from "../context/settingsContext";

const ControlPage = () => {
    //const [activeArea,setActiveArea] = useState("stc")
    const { currentSettings } = useSettings();
    const {areaData,activeArea,setActiveArea,sensorSubscription,startSubscription,stopSubscription,sensorData} =useAreaContext()
    const [activeSensors,setActiveSensors] = useState(sensorSubscription.length>0)

    useEffect(()=>{
        setActiveSensors(sensorSubscription.length>0?true:false);
        if (currentSettings[activeArea ].loggingInConsoleArea.value) console.log("Setting sensor data to ", sensorSubscription)
    }, [setActiveSensors,currentSettings,sensorSubscription])


    return (
    <>
        <h1 className="Tdsheadline-01 Tdsu-pb3">Control tower</h1>
        <AreaSelector></AreaSelector>
        <AreaMasterControl></AreaMasterControl>
        <AreaVehicles ></AreaVehicles>
        <AreaOverview ></AreaOverview>
    </>
  );
};

export default ControlPage;
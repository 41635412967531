import { TdsButton } from "@scania/tegel-react";
import {useWebSocketContext} from "../../context/websocketContext";
import { useUserContext } from "../../context/userContext";
import { useAreaContext } from "../../context/areaContext";

const AreaMasterControl = (props:any) =>{
    const { sendMessage } = useWebSocketContext();
    const {isPartOfGroup} = useUserContext()
    const {activeArea} = useAreaContext()
    const sendEmergencyStop=() =>{
        if (isPartOfGroup("VehicleControl")){
            if (typeof(activeArea) !== 'undefined' && activeArea != null && activeArea.length>0) {
                let msg = `{"action":"area-emergency-stop", "message":${JSON.stringify(activeArea)}}`
                sendMessage(msg)
                console.log("Send " + msg)
            }
        }
        else{
            alert("You don't have the correct access rights for sending vehicle commands!")
        }
    }

    return (
        <>
            <TdsButton text={"Emergency Stop"} type="button" variant="danger"  onClick={() => {sendEmergencyStop()}} fullbleed></TdsButton>

        </>
    )
}

export default AreaMasterControl;
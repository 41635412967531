const arrayToRect = (pointList:Array<number>) =>{
    return {
      x: pointList[0],
      y: pointList[1],
      width: pointList[2],
      height: pointList[3]
    }
  }

const convertToCanvasCoord = (canvas:HTMLCanvasElement, coord:{x:number,y:number,[propName: string]: any;}) =>{
    return {
        ...coord,
        ...{
            x: coord.x + canvas.width/2,
            y: -1*coord.y + canvas.height/2,
        }
    }
}

// Function to get the mouse position
const getMousePos = (canvas:HTMLCanvasElement, event:any) => {
    var rect = canvas.getBoundingClientRect();
    return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
    };
}
export {arrayToRect,getMousePos,convertToCanvasCoord}

import { TdsBodyCell, TdsHeaderCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader } from '@scania/tegel-react';
import { useAreaContext } from '../../context/areaContext';
import { useSettings } from '../../context/settingsContext';

const VehicleData = () =>{


    const { vehicleData } = useAreaContext();
    const { currentSettings } = useSettings();

    const convert = (arr:any,precision:number)=>{
        let newArr:any[] = [];
        for(let i=0; i<arr.length; i++){
            newArr.push(arr[i].toFixed(precision));
        }
        return newArr;
    }

    return (
        <TdsTable vertical-dividers="true" compact-design="true">
        <TdsTableHeader>
            <TdsHeaderCell cell-key="track" cell-value="Vehicle"></TdsHeaderCell>
            <TdsHeaderCell cell-key="status" cell-value="Status"></TdsHeaderCell>
            <TdsHeaderCell cell-key="topic" cell-value="Topic"></TdsHeaderCell>
            <TdsHeaderCell cell-key="heading" cell-value="Heading"></TdsHeaderCell>
            <TdsHeaderCell cell-key="speed" cell-value="Speed"></TdsHeaderCell>
            <TdsHeaderCell cell-key="position" cell-value="Position"></TdsHeaderCell>
            <TdsHeaderCell cell-key="ref" cell-value="Reference"></TdsHeaderCell>
        </TdsTableHeader>
        <TdsTableBody>

           {vehicleData && Object.keys(vehicleData).length>0 ? (
                //let keys = Object.keys(sensorData[0]);
                Object.values(vehicleData).map((msg:any, idx) => (
                    Object.entries(msg).map((trackid,data)=>(
                    <TdsTableBodyRow key={trackid[0]}>
                    <TdsBodyCell
                        cell-value={trackid[1]!["vehid"]}
                        cell-key="track"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["status"]}
                        cell-key="class"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[0]}
                        cell-key="topic"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["heading"]}
                        cell-key="heading"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["speed"]}
                        cell-key="speed"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={convert([trackid[1]!["position"]["x"],trackid[1]!["position"]["y"]],currentSettings.sensor.decimalPlacesPosition.value).toString()}
                        cell-key="position"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]!["position"]["ref"]}
                        cell-key="position"
                        disable-padding="false"
                    ></TdsBodyCell>
                    </TdsTableBodyRow>

                ))))) : (
                <TdsTableBodyRow></TdsTableBodyRow>
                )}
        </TdsTableBody>
        </TdsTable>
    )
}
export default VehicleData;
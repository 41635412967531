import { useState, useContext, createContext, useEffect } from "react";

const localFileName = 'settings_v24' ?? 'settings';
const defaultSettings = {
  general:{
    actOnResize: {type:'bool',value:false,text:"React on resize, effects the dashboard, problems on mobile devices if on"},
  },
  /*area:{ //moved to each area
    autoSubscribe: {type: "bool", value: false, text:"Auto start sensor data fetching"},
    xscale:{type: "float", value: 1, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
    yscale:{type: "float", value: 1, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
    showGrid:{type: "bool", value: true, text:"Show grid"},
    gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
    loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
  },*/
  sensor:{
    decimalPlacesPosition: {type:'int',value:6,text:"Decimal places to show for position",min:1,max:15,step:1, unit:"count"},
    decimalPlacesVelocity: {type:'int',value:2, text:"Decimal places to show for velocity",min:1,max:15,step:1, unit:"count"},
    loggingInConsoleSensor: {type:'bool',value:false,text:"Logging in console"}
  },
  vehicleControl:{
    stopCoordTimeAhead:{ type: 'int', value:1, text:"Use predicted position these many seconds ahead as stop cmd", min:1,max:10,step:1,unit:"seconds"}
  },
  connection:{
    sendDataViaWS: {type:'bool',value:false,text:"Send data to backend"},
    sendInterval: {type:'int',value:10,text:"Interval in seconds",min:1,max:120,step:1, unit:"s"},
    messagesInHistoryWS: {type:'int',value:15,text: "Message to keep in history",min:1,max:200,step:1,unit:"-"},
    loggingInConsoleForWS: {type:'bool',value:false,text:"Logging in console"}
  },
    stc:
      {
        name: {type: "str",value:'Scania STC',text: "Area Name"},
        shortname: {type: "str",value:'stc',text: "Area Name",enabled:false},
        directconnect: {type: "bool",value:true,text: "Use direct connection to sensor (not via Viscando)",enabled:true},
        topic: {type: "area", text:"Topics",rows:5, value:"viscando.testtrack.+,kth.stc.status.+,scania.stc.status.+,scania.stc.predictions,scania.stc.status_pred"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [17.62529591,59.16628516],enabled:"false"},
        view: {type: "area", text:"View area sensor",row:6, value: [
                [17.624988502064738,59.166359074215094],
                [17.624996344155129,59.166400990011653],
                [17.625111117119623,59.166567352359998],
                [17.625126127960790,59.166573441563109],
                [17.625281783481594,59.166600695670624],
                [17.625492401347302,59.166596955161502],
                [17.625645274385583,59.166563024704892],
                [17.625599028560199,59.166575787097365],
                [17.625685489698242,59.166556411845313],
                [17.625840703192587,59.166500822255280],
                [17.625958279640830,59.166422459396848],
                [17.626076837368544,59.166366973964735],
                [17.626010205513062,59.166282614561538],
                [17.625866940453747,59.166197355696404],
                [17.625392509817868,59.166281233609695],

        ],enabled:"false"},
        projection: {type: "dropdown",value:"SWEREF99",options:["WGS84","SWEREF99","LOCAL"],text:"Projection for control map"},
        A:{type: "area",text:"A point",rows:2, value:[17.625897,59.166282],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[17.625720,59.166499],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[17.625297,59.166419],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 3, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
        yscale:{type: "float", value: 3, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
      valhal:
      {
        name: {type: "str",value:'Valhal (Simulation)',text: "Area Name"},
        shortname: {type: "str",value:'valhal',text: "Area Name", enabled:false},
        topic: {type: "area", text:"Topics",rows:5, value:"scania.valhal.sensor.+.tracks,scania.valhal.status.+,scania.valhal.predictions,scania.valhal.status_pred"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [0,0],enabled:"false"},
        view: {type: "area", text:"View area sensor",rows:6, value: [
          [-500,0],
          [-500,10],
          [500,10], 
          [500,-10], 
          [-500,-10], 
          [-500,0], 
        ],enabled:"false"},
        projection: {type: "dropdown",value:"LOCAL",options:["WGS84","SWEREF99","LOCAL"],text:"Projection"},
        A:{type: "area",text:"A point",rows:2, value:[50,0],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[0,50],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[80,80],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 1, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
        yscale:{type: "float", value: 1, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
      itrl:
      {
        name: {type: "str",value:'ITRL',text: "Area Name"},
        shortname: {type: "str",value:'itrl',text: "Area Name",enabled:false},
        topic: {type: "area", text:"Topics",rows:5, value:"kth.itrl.sensor.+.tracks,kth.itrl.status.+,scania.itrl.predictions,scania.itrl.status_pred"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [18.0679815,59.3508492],enabled:"false"},
        view: {type: "area", text:"View of sensor",rows:6, value: [[18.0679815,59.3508492]],enabled:"false"},
        projection: {type: "dropdown",value:"SWEREF99",options:["WGS84","SWEREF99","LOCAL"],text:"Projection"},
        A:{type: "area",text:"A point",rows:2, value:[18.0680906, 59.3508686],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[18.0679814, 59.3508216],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[18.0680049, 59.3508505],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 28, text:"X scale", min:1, max: 100, step:1, unit:"-" },
        yscale:{type: "float", value: 28, text:"Y scale", min:1, max: 100, step:1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
  /*dashboard:{
    showDevParameters:{type:'bool',value:false,text:"Show develop parameters"},
    drawInfoDisplayDebug:{type:'bool',value:false,text:"Show debug info for warning display"},
    drawLaneDebug:{type:'bool',value:false,text:"Show Lane drawing debug lines"},
    drawLaneInfoDebug:{type:'bool',value:false,text:"Show Lane information debug info"},
    maxSpeedVehicle: {type:'int',value:90,text:"Maximum speed of vehicle",min:30,max:130,step:10,unit:"km/h"},
    warningDisplayTimeOut:{type:'int',value:20,text:"Time to display received warning",min:1,max:60,step:1,unit:"s"},
    laneCountingFromLeft:{type:'bool',value:false,text:"Start lane number 1 from left instead of right"},
  },
  location:{
    useSimulator: {type:'bool',value:true,text:"Simulate position"},
    simStart:{type:"str",value:"u6scfd3d4exq",text:"Start position in geohash (moraberg u6s8f0emv3j0)"},
    useRoute: {type:'bool',value:false,text:"Create a route by using endpoint in next field"},
    simEnd: {type:"str",value:"u6sfhmyp4j6q",text:"End position in geohash, only used if route is selected above (u6knzd3br)"},
    simEpsg: {type: "str", value:"3006",text:"EPSG number"},
    simSpeedMs: {type:"int", value:25, text:"Speed of simulator", min: 1,max:250,step:10,unit:"m/s"},
    simSampleTime: {type:'int',value:5, text: "Time between simulator updates", min:1,max:120,step:1,unit:"s"},
  },
  map:{
    mapZoomLevel: {type:"int", value:16, text:"Zoom level", min: 1,max:20,step:1,unit:"-"},
    eventFilter:{type: "str", value:"SE",text:"Filter publisher id starting with this string"},
    fetchExternalData:  {type:'bool',value:false,text:"Fetch external data (not interchange)"},
    eventFetcher: {type:"int", value:60, text:"Fetch external data at this interval", min: 30,max:300,step:30,unit:"s"},
    clusteringEps: {type:"int", value:32, text:"Maximum radius of the neighbourhood",min:1,max:200,step:1,unit:"-"},
    clusteringMinWeight: {type:"int", value:10, text:"Minimum weight of points required to form a cluster",min:1,max:200,step:1,unit:"-"}
  },*/
};
export type SettingsContextT = {
  currentSettings: any
  setSettings: (o:object)=> void
  setToDefaultSettings: ()=>void
}
export const SettingsContext = createContext<SettingsContextT>({
  currentSettings: defaultSettings,
  setSettings: ()=>{},
  setToDefaultSettings: ()=>{}
});

export function SettingsProvider(props) {
  const [currentSettings, setSettings] = useState(() => {
    try {
      return {...defaultSettings,...JSON.parse(localStorage.getItem(localFileName)||'{}')} ?? defaultSettings;
    } catch {
      return defaultSettings;
    }
  });

  const setToDefaultSettings = ()=>{
    setSettings(defaultSettings);
  }


  const contextValue = {
    currentSettings,
    setSettings,
    setToDefaultSettings
  };


  useEffect(()=>{
    localStorage.setItem(localFileName, JSON.stringify(currentSettings));
  }, [currentSettings])

  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  return useContext(SettingsContext);
}
import { useEffect, useRef, useState } from "react";
import { useResizeContext } from "../../context/resizeContext";
import { convertToCanvasCoord } from "./AreaCanvasTools";
import proj4 from "proj4";
import { useAreaContext } from "../../context/areaContext";
import { useSettings } from "../../context/settingsContext";

const AreaOverview = ()=>{
    const {currentSettings} = useSettings();
    const {areaData,activeArea,sensorData,legend,predictionData,vehicleData, vehiclePredictionData} = useAreaContext()
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [ctx2,setCtx2] = useState<CanvasRenderingContext2D | null>(null);
    const {width, height} = useResizeContext();
    const [x_max,setXmax] = useState(width*0.5)
    const [y_max,setYmax] = useState(height*0.5)
    const getMaxSize = (box:any) => {
        return ( Math.max(...box!.extents));
      }
       
    useEffect(()=>{
        if (canvas.current){
            if (currentSettings[activeArea].loggingInConsoleArea.value) console.log("updating context")
            canvas.current.width = x_max;
            canvas.current.height = y_max;
            setCtx2(canvas.current.getContext("2d"));
            
            drawArea()
            
            //drawCircle({x:0,y:0,r:2},{color:"red",width:2})
            let areaCenter, A,B,C,view;
            if (areaData[activeArea].projection.value !== "LOCAL"){
                areaCenter=proj4(areaData[activeArea].projection.value,areaData[activeArea].origin.value);
                A=proj4(areaData[activeArea].projection.value,areaData[activeArea].A.value);
                B=proj4(areaData[activeArea].projection.value,areaData[activeArea].B.value);
                C=proj4(areaData[activeArea].projection.value,areaData[activeArea].C.value);
                view =areaData[activeArea].view.value.map(point =>{
                    return proj4(areaData[activeArea].projection.value,point)
                });
            } else{
                areaCenter = areaData[activeArea].origin.value;
                A = areaData[activeArea].A.value;
                B = areaData[activeArea].B.value;
                C = areaData[activeArea].C.value;
                view =areaData[activeArea].view.value
            }
            drawSensorRange("sensor",view,areaCenter)
            drawCircle(convertToCanvasCoord(canvas.current,{x:0,y:0,r:1}),{color:"green",width:2})
            drawDestinations("A",A,areaCenter);
            drawDestinations("B",B,areaCenter);
            drawDestinations("C",C,areaCenter);
            
            Object.entries(sensorData).forEach(([sensName, sensData])=> {
                //console.log(`${sensName} ${sensData}`); // "a 5", "b 7", "c 9"
                Object.entries(sensData??{}).forEach(([trackID, trackData])=> {
                    if (currentSettings[activeArea].loggingInConsoleArea.value) console.log(`${trackID} - ${trackData.lalo}`)
                    //proj4.defs("EPSG:3006","+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
                    let detectionPoint;
                    if (areaData[activeArea].projection.value !== "LOCAL"){
                        detectionPoint = proj4(areaData[activeArea].projection.value,trackData.lalo.toReversed());
                    }else{
                        detectionPoint = trackData.lalo.toReversed();
                    }
                    if (currentSettings[activeArea].loggingInConsoleArea.value) console.log(detectionPoint)
                    drawCircle(convertToCanvasCoord(canvas.current!,{x:(detectionPoint[0]-areaCenter[0])*currentSettings[activeArea].xscale.value,y:( detectionPoint[1]-areaCenter[1] )*currentSettings[activeArea].yscale.value,r:getMaxSize(trackData.bbox)*currentSettings[activeArea].xscale.value}),{color:legend[trackData.class_id][1],width:legend[trackData.class_id][2]})
                })
              });
            Object.entries(vehicleData).forEach(([vehName,vehData])=>{
                Object.entries(vehData??{}).forEach(([trackID, trackData])=> {
                    drawVehicle(trackData.vehid,trackData.position!,areaCenter)
                })
            })
            //if (Object.keys(predictionData).length !==0){
            /*Object.entries(predictionData).forEach(([predName,predData])=>{
                Object.entries(predData??{}).forEach(([trackID, trackList])=> {
                    drawPredictions(trackList,areaCenter)
                })
            });*/
            if (Object.keys(predictionData).length) drawPredictions(predictionData,areaCenter,{color:"BlueViolet",width:2})
            if (Object.keys(vehiclePredictionData).length>0) drawPredictions(vehiclePredictionData,areaCenter,{color:"cyan",width:2})                  
            if (currentSettings[activeArea].showGrid.value) drawGrid()
            drawLegend();

        }
    },[canvas.current,currentSettings,sensorData,predictionData,vehicleData]
    )
    const drawPredictions = (tracks,areaCenter,style={color:"yellow",width:2}) => {
        //let style={color:"yellow",width:2};
        if (ctx2 && Object.keys(tracks).length>0){            
            Object.entries(tracks??{}).forEach(([tn,track])=>{
                if (tn!=="time"){
                    const line = new Path2D;
                    if (Array.isArray(track)){
                        for (const point of track){
                            if (Array.isArray(point)){
                                let point2
                                if (areaData[activeArea].projection.value !=="LOCAL"){
                                    point2 = proj4(areaData[activeArea].projection.value,point);
                                } else {
                                    point2 = point
                                }
                                
                                let [x,y] = point2;

                                let p = convertToCanvasCoord(canvas.current!,{x:(x-areaCenter[0])*currentSettings[activeArea].xscale.value,y:( y-areaCenter[1] )*currentSettings[activeArea].yscale.value})
                                line.lineTo(p.x,p.y)
                            }
                        }
                    }
                    ctx2.strokeStyle = style.color;
                    ctx2.setLineDash([2,1]);
                    ctx2.lineWidth = style.width;
                    ctx2.stroke(line);
                }
            })

        }
    }
     
    const drawSensorRange = (name:string, points:any[],areaCenter:any)=>{
        let color = "yellow";
        if (ctx2){
            let coord=Number[2];
            ctx2.globalAlpha = 0.5;
            ctx2.fillStyle = 'LemonChiffon';
            ctx2.beginPath();
            Object.entries(points).forEach(([idx,point])=>{
                coord = convertToCanvasCoord(canvas.current!,{x:(point[0].valueOf()-areaCenter[0])*currentSettings[activeArea].xscale.value,y:( point[1]-areaCenter[1] )*currentSettings[activeArea].yscale.value,r:1})
                //drawCircle(coord,{color:color,width:1})
                if (idx === "0"){
                    ctx2.moveTo(coord.x,coord.y);
                }
                ctx2.lineTo(coord.x,coord.y)
                return null;
            })
            ctx2.closePath();
            ctx2.fill();
            ctx2.globalAlpha = 1;
        }
    }
    const drawLegend  = () => {
        if (ctx2){
        ctx2.font = `${Math.round(x_max*0.02)}px bolder Courier`;
        const X = 0.01*x_max;
        let startY = 0.04*y_max;
        Object.entries(legend).forEach((entry:any)=>{
            ctx2.fillStyle = entry[1][1];
            ctx2.fillText(entry[1][0], X,startY);
            startY += 0.03*y_max;
        })
        }
    }
    
    const drawVehicle  = (name:string,point:any,areaCenter:any) => {
        let color = 'purple'
        if (ctx2){
            let point2
            if (areaData[activeArea].projection.value !=="LOCAL"){
                point2 = proj4(areaData[activeArea].projection.value,point);
            } else {
                point2 = point
            }
            let coord = convertToCanvasCoord(canvas.current!,{x:(point2.x-areaCenter[0])*currentSettings[activeArea].xscale.value,y:( point2.y-areaCenter[1] )*currentSettings[activeArea].yscale.value,r:5})
            //drawCircle(coord,{color:color,width:5})
            let w = 0.01 * x_max
            let h = 0.01 * y_max
            ctx2.fillStyle = color;
            ctx2.fillRect(coord.x-w/2, coord.y-h/2, w, h);
            ctx2.font = `${Math.round(x_max*0.01)}px bolder Courier`;
            ctx2.fillStyle = color;
            ctx2.fillText(name, coord.x*1.02, coord.y*1.04);
        }
        
    }
     
 
    const drawDestinations = (name:string,point:any,areaCenter:any) =>{
        let color = 'cyan'
        if (ctx2){
            let coord = convertToCanvasCoord(canvas.current!,{x:(point[0]-areaCenter[0])*currentSettings[activeArea].xscale.value,y:( point[1]-areaCenter[1] )*currentSettings[activeArea].yscale.value,r:5})
            drawCircle(coord,{color:color,width:5})
            ctx2.font = `${Math.round(x_max*0.03)}px bolder Courier`;
            ctx2.fillStyle = color;
            ctx2.fillText(name, coord.x*1.02, coord.y*1.02);
        }
    }

    const drawGrid = ()=>{
        if (ctx2){
            ctx2.setLineDash([1,1]);
            for (let x = 0; x < x_max; x += currentSettings[activeArea].gridSpacing.value) {
                drawLine({x:x,x1:x, y:0,y1:y_max},{color:'ivory',width:1})
            }
            for (let y = 0; y < y_max; y += currentSettings[activeArea].gridSpacing.value) {
                drawLine({x:0,x1:x_max, y:y,y1:y},{color:'ivory',width:1})
            }
        }
    }
    const drawArea = ()=>{
        if (ctx2){
            ctx2.fillStyle = 'gray'
            ctx2.fillRect(0, 0, x_max, y_max);
        }
    }
    const drawLine = (info, style = {color:"black",width:1}) => {
        //console.log("DASH: Draw line: ",info)
        const { x, y, x1, y1 } = info;
        if (ctx2){
            ctx2.beginPath();
            ctx2.moveTo(x, y);
            ctx2.lineTo(x1, y1);
            ctx2.strokeStyle = style.color;
            ctx2.lineWidth = style.width;
            ctx2.stroke();
        }
      };
    const drawCircle = (circle:any, style={color:"black",width:1})=>{
        const { x, y, r } = circle;
        if (ctx2) {
            ctx2.beginPath();
            ctx2.arc(x, y, r, 0, 2 * Math.PI);
            ctx2.strokeStyle = style.color;
            ctx2.lineWidth = style.width;
            ctx2.stroke();
            ctx2.closePath();
        }
    }
    return (
        <>
            <p>Plot</p>
            <div style={{color:"BlueViolet"}}>Prediction</div>
            <div style={{color:"Cyan"}}>Command</div>
            <div style={{color:"Gold"}}>Sensor Range</div>
            <canvas ref={canvas}></canvas>
            {/*<div style={{position: "relative"§}}>
            <canvas id="layer1" width="100" height="100" style={{zIndex:0, position: "absolute", top: 0, left: 0}}></canvas>
            <canvas ref={canvas} id="layer2" width="100" height="100" style={{zIndex:1, position: "absolute", top: 0, left: 0}}></canvas>
            </div>*/}
        </>
    )
}
export default AreaOverview;
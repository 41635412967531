import { FormEvent, useRef } from 'react';
import { TdsRadioButton, TdsButton } from '@scania/tegel-react';
import { useAreaContext } from '../../context/areaContext';

const AreaSelector = () =>{
    const { areaData,startSubscription ,stopSubscription,sensorSubscription,activeArea,setActiveArea,emptyDataBuffers } = useAreaContext();

    const formRef = useRef<HTMLFormElement>(null);
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const formEl = e.target as HTMLFormElement;
        const elements = formEl.elements;
        setActiveArea((elements)['areas'].value)
        //startSubscription(        (elements)['areas'].value,);
      };

    return(
        <>
        <form onSubmit={onSubmit} ref={formRef}>
        <fieldset key="areas" className="tds-u-pb2">
        <legend className="tds-headline-05 tds-u-pb1">{"areas"}</legend>
          {Object.values(areaData).map((fieldset: any) => {
          return (
                <TdsRadioButton
                    key={fieldset.name.value}
                    name={"areas"}
                    value={fieldset.shortname.value}
                    radio-id={fieldset.name.value}
                    required={fieldset.required}
                    checked={fieldset.shortname.value===activeArea}
                    ref={(ref) => {
                    if (ref) {
                        // Note: workaround for React, in a real world app
                        // listeners should be removed as well to prevent memory leaks
                        ref.addEventListener('tdsChange', (e: any) => {
                        //console.log('tdsChange event.detail', e.detail);
                        });

                        (ref as any).handleChange = () => {
                            console.log('should not work');
                        };
                    }
                }}
              >
                <span slot="label">
                  {fieldset.name.value}
                </span>
              </TdsRadioButton>
            );
          })}
        </fieldset>
      <TdsButton text={sensorSubscription.length===0?"Subscribe":"Change Subscribtion"} type="submit"></TdsButton>
      {sensorSubscription.length>0?<TdsButton text="Unsubscribe" type="button" variant="danger" onClick={(e)=>{stopSubscription()}}></TdsButton>:null}
      <TdsButton text={"Empty buffers"} type="button" variant="secondary"  onClick={() => {emptyDataBuffers()}}></TdsButton>
      {sensorSubscription.length!==0 && (
        <>
          <p className="tds-headline-03">Active subscription.</p>
          <pre>
            {sensorSubscription}
            {/*submittedData.map((dish: any, idx: any) => (
              <Fragment key={dish}>
                {dish}: {(idx + 1) * 10000} SEK
                <br />
              </Fragment>
            ))*/}
          </pre>
        </>
      )}
        </form>

        </>
      )
}

export default AreaSelector;
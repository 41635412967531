import { TdsTable, TdsTableHeader, TdsHeaderCell, TdsTableBody, TdsTableBodyRow, TdsBodyCell } from '@scania/tegel-react';
import { useAreaContext } from '../../context/areaContext';

const SensorLatency = () => {
    const {latencyData} = useAreaContext();

    return (
        <>
        Latency
        <TdsTable vertical-dividers="true" compact-design="true">
        <TdsTableHeader>
            <TdsHeaderCell cell-key="sensor" cell-value="Sensor"></TdsHeaderCell>
            <TdsHeaderCell cell-key="latency" cell-value="Latency ms"></TdsHeaderCell>
        </TdsTableHeader>
        <TdsTableBody>
           {latencyData && Object.keys(latencyData).length>0 ? (
                //let keys = Object.keys(latencyData[0]);
                Object.values(latencyData).map((msg:any, idx) => (
                    Object.entries(msg).map((trackid,data)=>(
                    <TdsTableBodyRow key={trackid[0]}>
                    <TdsBodyCell
                        cell-value={trackid[0]}
                        cell-key="sensor"
                        disable-padding="false"
                    ></TdsBodyCell>
                    <TdsBodyCell
                        cell-value={trackid[1]}
                        cell-key="latency"
                        disable-padding="false"
                        text-align="center"
                    ></TdsBodyCell>
                    </TdsTableBodyRow>
             ))))) : (<TdsTableBodyRow></TdsTableBodyRow>
                )
                }
        </TdsTableBody>
        </TdsTable>
        <div>Note: this is latency from picture taken in sensor to processing in Scania AWS</div>
        </>
    )
}
export default SensorLatency;